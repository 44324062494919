import React from 'react'
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  DateInput,
  NumberInput,
  Toolbar,
  SaveButton,
  NullableBooleanInput,
} from 'react-admin'
import { Grid } from '@mui/material'

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const CustomerEditAction = () => {
  const transformBeforeUpdate = (data: any) => {
    return {
      ...data,
      contactAddress: Object.values(data.contactAddress).every((value) => value == null) ? null : data.contactAddress,
    }
  }

  return (
    <Edit mutationMode="pessimistic" transform={transformBeforeUpdate}>
      {/*remove syncWithLocation after api platform start using react-router-dom >= 6.20.1
           https://github.com/marmelab/react-admin/issues/9468#issuecomment-1836852096
           */}
      <TabbedForm syncWithLocation={false} toolbar={<UserEditToolbar />} noValidate>
        <FormTab label="Customer">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput source="originId" label="CA ID" readOnly />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="status" readOnly />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="customerName" required />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="shortName" />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="bin" />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="tin" />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="vatId" label="VAT ID" />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="invoiceEmail" />
            </Grid>
            <Grid item xs={6}>
              <NullableBooleanInput source="invoiceEmailIsFreemail" label={'Is invoice email freemail?'} readOnly />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="affiliatePartner" required />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="paymentMethod" required />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="businessField" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="businessSize" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="customerType" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="registrationSource" required />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="duplicityReason" />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="countryCode" readOnly />
            </Grid>
            <Grid item xs={6}>
              <DateInput source="created" readOnly />
            </Grid>
            <Grid item xs={6}>
              <DateInput source="updated" readOnly />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="deleteReason" readOnly />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="deleteUser" label={'Name of user who delete this customer'} readOnly />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="guarantorCrmId" readOnly />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="HQ address">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput source="hqAddress.id" label="HQ Address ID" />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="hqAddress.street" label="HQ Address Street" />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="hqAddress.buildingNumber" label="HQ Address Building Number" required />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="hqAddress.city" label="HQ Address City" required />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="hqAddress.zip" label="HQ Address ZIP" required />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="hqAddress.cityPart" label="HQ Address City Part" />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="hqAddress.countryCode" label="HQ Address Country Code" required />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="hqAddress.longitude" label="HQ Address Longitude" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="hqAddress.latitude" label="HQ Address Latitude" />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Contact Address">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput source="contactAddress.id" label="HQ Address ID" disabled />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="contactAddress.street" label="Contact Address Street" />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="contactAddress.buildingNumber" label="Contact Address Building Number" required />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="contactAddress.city" label="Contact Address City" required />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="contactAddress.zip" label="Contact Address ZIP" required />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="contactAddress.cityPart" label="Contact Address City Part" />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="contactAddress.countryCode" label="Contact Address Country Code" required />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="contactAddress.longitude" label="Contact Address Longitude" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="contactAddress.latitude" label="Contact Address Latitude" />
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
